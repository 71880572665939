import {
    SET_DASHBOARD_DETAILS_ACTIVE,
    SET_DASHBOARD_DETAILS_NEW,
    SET_DASHBOARD_DETAILS_UNSUBSCRIBED,
    SET_DASHBOARD_OVERVIEW,
} from './-mutation-types';
import { useDashboardStore } from '~/stores/dashboard';
import DashboardService from '~/services/api/crm/DashboardService';
import { type DashboardDetailQueryParametersDto } from '~/types/Dashboard';

const Actions = {
    async fetchDetailsActive(filters: DashboardDetailQueryParametersDto) {
        const dashboardStore = useDashboardStore();
        const dashboardService = new DashboardService();
        const response = await dashboardService.fetchDetails('active', filters);

        dashboardStore[SET_DASHBOARD_DETAILS_ACTIVE](response);
    },
    async fetchDetailsNew(filters: DashboardDetailQueryParametersDto) {
        const dashboardStore = useDashboardStore();
        const dashboardService = new DashboardService();
        const response = await dashboardService.fetchDetails('new', filters);

        dashboardStore[SET_DASHBOARD_DETAILS_NEW](response);
    },
    async fetchDetailsUnsubscribed(filters: DashboardDetailQueryParametersDto) {
        const dashboardStore = useDashboardStore();
        const dashboardService = new DashboardService();
        const response = await dashboardService.fetchDetails('unsubscribed', filters);

        dashboardStore[SET_DASHBOARD_DETAILS_UNSUBSCRIBED](response);
    },
    async fetchOverview() {
        const dashboardStore = useDashboardStore();
        const dashboardService = new DashboardService();
        const response = await dashboardService.fetchOverview();

        dashboardStore[SET_DASHBOARD_OVERVIEW](response);
    },
};

export default Actions;
