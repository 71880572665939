import {
    SET_DASHBOARD_DETAILS_ACTIVE, SET_DASHBOARD_DETAILS_NEW, SET_DASHBOARD_DETAILS_UNSUBSCRIBED, SET_DASHBOARD_OVERVIEW,
} from './-mutation-types';
import { useDashboardStore } from '~/stores/dashboard';
import { type ReadDashboardDetailDto, type ReadDashboardOverviewDto } from '~/types/Dashboard';

const Mutations = {
    [SET_DASHBOARD_DETAILS_ACTIVE](payload: ReadDashboardDetailDto | null) {
        const dashboardStore = useDashboardStore();
        dashboardStore.dashboardDetailsActive = payload;
    },
    [SET_DASHBOARD_DETAILS_NEW](payload: ReadDashboardDetailDto | null) {
        const dashboardStore = useDashboardStore();
        dashboardStore.dashboardDetailsNew = payload;
    },
    [SET_DASHBOARD_DETAILS_UNSUBSCRIBED](payload: ReadDashboardDetailDto | null) {
        const dashboardStore = useDashboardStore();
        dashboardStore.dashboardDetailsUnsubscribed = payload;
    },
    [SET_DASHBOARD_OVERVIEW](payload: ReadDashboardOverviewDto | null) {
        const dashboardStore = useDashboardStore();
        dashboardStore.dashboardOverview = payload;
    },
};

export default Mutations;
