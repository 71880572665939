import {
    GET_DASHBOARD_DETAILS_ACTIVE, GET_DASHBOARD_DETAILS_NEW, GET_DASHBOARD_DETAILS_UNSUBSCRIBED, GET_DASHBOARD_OVERVIEW,
} from './-getter-types';
import { useDashboardStore } from '~/stores/dashboard';
import { type ReadDashboardDetailDto, type ReadDashboardOverviewDto } from '~/types/Dashboard';

const Getters = {
    [GET_DASHBOARD_DETAILS_ACTIVE]: (): ReadDashboardDetailDto | null => {
        const dashboardsStore = useDashboardStore();

        return dashboardsStore.dashboardDetailsActive;
    },
    [GET_DASHBOARD_DETAILS_NEW]: (): ReadDashboardDetailDto | null => {
        const dashboardsStore = useDashboardStore();

        return dashboardsStore.dashboardDetailsNew;
    },
    [GET_DASHBOARD_DETAILS_UNSUBSCRIBED]: (): ReadDashboardDetailDto | null => {
        const dashboardsStore = useDashboardStore();

        return dashboardsStore.dashboardDetailsUnsubscribed;
    },
    [GET_DASHBOARD_OVERVIEW]: (): ReadDashboardOverviewDto | null => {
        const dashboardsStore = useDashboardStore();

        return dashboardsStore.dashboardOverview;
    },
};

export default Getters;
