import { type ReadDashboardDetailDto, type ReadDashboardOverviewDto } from '~/types/Dashboard';

export type DashboardState = {
    dashboardDetailsActive: ReadDashboardDetailDto | null;
    dashboardDetailsNew: ReadDashboardDetailDto | null;
    dashboardDetailsUnsubscribed: ReadDashboardDetailDto | null;
    dashboardOverview: ReadDashboardOverviewDto | null;
}

export const defaultStateData = (): DashboardState => ({
    dashboardDetailsActive: null,
    dashboardDetailsNew: null,
    dashboardDetailsUnsubscribed: null,
    dashboardOverview: null,
});

export const DefaultState = (): DashboardState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
